import React from 'react';
import ReactDOM from 'react-dom/client';
import {SnackbarProvider, useSnackbar} from 'notistack';
import { IconButton } from "@mui/material";
import { Close as IconClose } from '@mui/icons-material';

import 'static/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


interface SnackbarCloseButtonProps {
  snackbarKey: string | number,
  test: number
}

const SnackbarCloseButton = (props: SnackbarCloseButtonProps) => {
  const {closeSnackbar} = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
      <IconClose style={{color:'white'}} />
    </IconButton>
  );

}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <SnackbarProvider 
      autoHideDuration={3000} 
      maxSnack={3} 
      action={snackbarKey => <SnackbarCloseButton test={1} snackbarKey={snackbarKey}/>}
    >
      <App />
    </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
