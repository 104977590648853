import React, {useState} from 'react';
import { Backdrop, CircularProgress } from '@mui/material';


export const CircularSpinner = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" size={100} thickness={2}/>
        </Backdrop>
    );
}

export default CircularSpinner;