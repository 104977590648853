import React from "react";
import { Typography, Dialog, DialogTitle, 
    DialogContent, DialogActions, Button } from "@mui/material";

export interface ConfirmationProps {
    title: string,
    body: string,
    onCancel: React.MouseEventHandler,
    onConfirm: React.MouseEventHandler
}

export const ConfirmationDialog = (props: ConfirmationProps) => {

  return (
    <Dialog open={true} maxWidth='sm' fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onConfirm} color="primary" variant="contained">
          Confirm
        </Button>
        <Button onClick={props.onCancel} color="error" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
