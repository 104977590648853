import React, {useEffect, useRef, useState} from 'react';
import { useSnackbar} from 'notistack';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AxiosError, AxiosResponse } from 'axios';
import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, MuiEvent } from '@mui/x-data-grid';
import { Stack, Grid, Typography } from "@mui/material";

import CircularSpinner from "common/CircularSpinner";
import { GET_API_URLS } from 'constants/apiUrls';
import {USER_ID} from 'constants/constants';
import {get} from 'utils/api';
import {formatPhoneNumber} from 'utils/formatter';
import 'static/css/App.css';
import { CallerStatus } from 'constants/enums';

const CustomToolbar = () => {
    return (
      <GridToolbarContainer >
        <GridToolbarColumnsButton style={{ color: '#212121' }}/>
        <GridToolbarFilterButton style={{ color: '#212121' }}/>
        <GridToolbarDensitySelector style={{ color: '#212121' }}/>
      </GridToolbarContainer>
    );
  }

export const ArchiveCallsDashboard = () => {
    const {enqueueSnackbar} = useSnackbar();

    const [archiveCallers, setArchiveCallers] = useState<any>([]);
    const [selectedCaller, setSelectedCaller] = useState<any>(null);
    const [selectedCallerLog, setSelectedCallerLog] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [callersPaginationModel, setCallersPaginationModel] = useState<any>({
        pageSize: 25,
        page: 0,
    });

    const isTimerStoppedRef = useRef<boolean>(false);

    let callersTimeoutId:any = null;

    useEffect(() => {
        loadArchiveCallersPeriodically(true);

        const cleanTimeouts = () => {
            clearTimeout(callersTimeoutId);
            isTimerStoppedRef.current = true;
        }

        return cleanTimeouts;
    }, []);

    const loadArchiveCallersPeriodically = async (showLoader: boolean) => {
        showLoader && setIsLoading(true);
        if (!isTimerStoppedRef.current) {
            get(GET_API_URLS.ARCHIVE_CALLERS(parseInt(sessionStorage.getItem(USER_ID) || '0'))).then((res:AxiosResponse) => {
                if (res.status === 200) {
                    setArchiveCallers(res.data);
                    callersTimeoutId = setTimeout(loadArchiveCallersPeriodically, 5000);
                } else {
                    enqueueSnackbar('Error while loading archive callers.', {variant: 'error'});    
                }
            }).catch((err:AxiosError) => {
                enqueueSnackbar('Error while loading archive callers.', {variant: 'error'});
            }).finally(() => {
                showLoader && setIsLoading(false);
            })
        }
    }

    const loadCallerLog = async (callerId:number, showLoader: boolean) => {
        showLoader && setIsLoading(true);
        get(GET_API_URLS.CALLER_LOG(parseInt(sessionStorage.getItem(USER_ID) || '0'), callerId)).then((res:AxiosResponse) => {
            if (res.status === 200) {
                setSelectedCallerLog(res.data.log.reverse());
            } else {
                enqueueSnackbar("Error while loading caller's log.", {variant: 'error'});    
            }
        }).catch((err:AxiosError) => {
            enqueueSnackbar("Error while loading caller's log.", {variant: 'error'});
        }).finally(() => {
            showLoader && setIsLoading(false);
        })
    };

    const callerColumns:GridColDef[] = [
        {
            field: 'session_token', headerName: 'Session ID', width: 210, align: 'center', headerAlign: 'center',
            editable: false, type: 'string'
        },
        {
            field: 'phone_number', headerName: 'Phone Number', width:150, align: 'center', headerAlign: 'center', 
            editable: false, type: 'string', valueGetter: (params) => {
                const phoneNumber = params.row.phone_number;
                const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
                return formattedPhoneNumber
            }
        },
        {
            field: 'status', headerName: 'Status', width: 190, align: 'center', headerAlign: 'center', editable: false,
            type: 'singleSelect', valueOptions: [
                {value: CallerStatus.SESSION_TERMINATED, label: 'Session Terminated'}
            ], renderCell: (params) => {
                return (
                    <div style={{color: '#d50000'}}>Session Terminated</div>
                );
            }
        },
    ];

    const googleMapsApiKey:string = process.env.REACT_APP_GOOGLE_API_KEY || '';
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: googleMapsApiKey
    });


    return (
        <>
            {
                (isLoading || !isLoaded) && <CircularSpinner/>
            }
            {isLoaded && (
                <Grid
                    container 
                    direction='row' 
                    wrap='nowrap' 
                    sx={{gridAutoFlow:'auto'}} 
                    spacing={2} 
                    style={{width: '100%', height:'100%'}}
                >
                    <Grid item style={{width:'570px'}}>
                        <DataGrid
                            autoHeight
                            columns={callerColumns}
                            rows={archiveCallers}
                            paginationModel={callersPaginationModel}
                            onPaginationModelChange={setCallersPaginationModel}
                            sx={{
                                "& .MuiDataGrid-cell": {
                                padding: '2px 10px'
                                }
                            }}
                            slots = {{
                                toolbar: CustomToolbar
                            }}
                            onRowClick={(params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
                                setSelectedCaller(params.row);
                                loadCallerLog(params.row.id, true);
                            }}
                        />
                    </Grid>
                    <Grid item xs style={{width: '100%'}}>
                        <Grid
                            container 
                            direction='column' 
                            wrap='nowrap'
                            sx={{gridAutoFlow:'auto'}}
                            spacing={2} 
                            style={{width:'100%', height:'100%'}}
                        >
                            <Grid item xs style={{minHeight:'100px'}}>
                                <GoogleMap 
                                    mapContainerClassName='map-container' 
                                    center={{
                                        lat:(selectedCaller && selectedCaller.latitude)?parseFloat(selectedCaller.latitude):39,
                                        lng:(selectedCaller && selectedCaller.longitude)?parseFloat(selectedCaller.longitude):-104,
                                    }} 
                                    zoom={(selectedCaller && selectedCaller.latitude && selectedCaller.longitude)?10:2}
                                >
                                    {(selectedCaller && selectedCaller.latitude && selectedCaller.longitude) && 
                                        <Marker 
                                            position={{
                                                lat:parseFloat(selectedCaller.latitude), 
                                                lng:parseFloat(selectedCaller.longitude)
                                            }}
                                        />
                                    }
                                </GoogleMap>
                            </Grid>
                            <Grid item style={{height:'40px'}}>
                                <Typography style={{fontWeight:'bolder'}}>Caller Log</Typography>
                            </Grid>
                            <Grid 
                                item 
                                style={{
                                    height:'400px', 
                                    overflow:'auto', 
                                    backgroundColor:'black', 
                                    color:'white',
                                    paddingLeft:'0px',
                                    paddingTop:'0px',
                                    marginLeft:'16px',
                                    marginTop:'8px'
                                }}
                            >
                                <Stack spacing={2}>
                                    {selectedCallerLog.map((callerLog:any, idx:number) => {
                                        const loggedOn = new Date(callerLog.timestamp);
                                        return (
                                            <Typography
                                                key={idx}
                                                align='left' 
                                                variant='caption'
                                                style={{marginTop:'5px'}}
                                            >
                                                {loggedOn.toLocaleString()}
                                                <span style={{marginLeft:'25px'}}>{callerLog.message}</span>
                                            </Typography>
                                        );
                                    })}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default ArchiveCallsDashboard;