import axios, {AxiosError, AxiosResponse} from 'axios';
import * as _ from 'lodash';

import {ROOT_URL, TOKEN_KEY, USER_ID} from 'constants/constants';

const request = axios.create({
    baseURL: ROOT_URL,
    timeout: 60000,
});

const authHeader = () => {
    if (sessionStorage.getItem(TOKEN_KEY)) {
        return {
            headers: {
                Authorization: `Token ${sessionStorage.getItem(TOKEN_KEY)}`,
            },
        }
    }

    return {};
};

export const isLoggedIn = () => {
    return (sessionStorage.getItem(TOKEN_KEY) != null);
};

export const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_ID);
};

const handleAuthentication = (data: any) => {
    if (data[TOKEN_KEY]) {
        sessionStorage.setItem(TOKEN_KEY, data[TOKEN_KEY]);
        return true;
    }

    return false;
};

export const get = (endpoint: string, params: object = {}, tokenExpireCallback: Function = () => {}) => {
    const resp = request.get(endpoint, {...authHeader(), params})
    resp.then((res: AxiosResponse) => {
      handleAuthentication(res.data);
    }).catch((res: AxiosError) => {
      if (_.get(res, 'response.status', null) === 401) {
        tokenExpireCallback();
      }
    });
    return resp;
  };

  export const post = (endpoint: string, data = {}, tokenExpireCallback: Function= () => {}) => {
    const resp = request.post(endpoint, data, authHeader())
    resp.then((res: AxiosResponse) => {
      handleAuthentication(res.data);
    }).catch((res: AxiosError) => {
      if (_.get(res, 'response.status', null) === 401) {
        tokenExpireCallback();
      }
    });
    return resp;
  };

  export const put = (endpoint: string, data = {}, tokenExpireCallback: Function= () => {}) => {
    const resp = request.put(endpoint, data, authHeader())
    resp.then((res: AxiosResponse) => {
      handleAuthentication(res.data);
    }).catch((res: AxiosError) => {
      if (_.get(res, 'response.status', null) === 401) {
        tokenExpireCallback();
      }
    });
    return resp;
  };

  export const del = (endpoint: string, tokenExpireCallback: Function= () => {}) => {
    const resp = request.delete(endpoint, authHeader())
    resp.then((res: AxiosResponse) => {
      handleAuthentication(res.data);
    }).catch((res: AxiosError) => {
      if (_.get(res, 'response.status', null) === 401) {
        tokenExpireCallback();
      }
    });
    return resp;
  };