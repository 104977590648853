import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import { Button, Stack, TextField } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import CircularSpinner from 'common/CircularSpinner';
import { post } from 'utils/api';
import { validatePhoneNumber } from 'utils/validation';
import { POST_API_URLS } from 'constants/apiUrls';
import { USER_ID } from 'constants/constants';


interface CallerRegistrationProps {
    onRegistrationComplete: (sessionToken:string) => void,
}

export const CallerRegistration = (props: CallerRegistrationProps) => {
    const {onRegistrationComplete} = props;

    const {enqueueSnackbar} = useSnackbar();

    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onPhoneTextboxBlur = () => {
        const errorMessage = validatePhoneNumber(phone)?'':'Please enter a valid phone number';
        setPhoneError(errorMessage);
    }

    const registerCaller = () => {
        if (phoneError) {
            enqueueSnackbar(phoneError, {variant:'warning'});
            return;
        }

        const data = {
            'phone_number': phone,
        }

        setIsLoading(true);
        post(POST_API_URLS.CALLER_REGISTRATION(parseInt(sessionStorage.getItem(USER_ID) || '0')), data).then((res:AxiosResponse) => {
            if (res.status === 201) {
                onRegistrationComplete(res.data.session_token);
                enqueueSnackbar('Registered the new caller successfully', {variant: 'success'});
                
            } else {
                enqueueSnackbar('Error while registering the caller', {variant: 'error'});
              }
        }).catch((res: AxiosError) => {
            enqueueSnackbar('Error while registering the caller', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        });
    } 

    const sx = {m:1, width:'35ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div>
                <form>
                    <Stack spacing={2}>
                        <TextField
                        error={!!phoneError}
                            sx={sx}
                            label='Phone Number'
                            value={phone}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setPhone(e.target.value);
                            }}
                            size='small'
                            helperText={phoneError}
                            onBlur={onPhoneTextboxBlur}
                            style={{alignSelf:'center'}}
                        />
                        <Button 
                            variant='contained' 
                            type='submit'
                            onClick={(e) => {
                                e.preventDefault();
                                registerCaller();
                            }}
                            style={{width:'302px', alignSelf:'center'}}
                        >
                            REGISTER
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );
}

export default CallerRegistration;