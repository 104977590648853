import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { AxiosError, AxiosResponse } from 'axios';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Stack } from '@mui/material';

import { put } from 'utils/api';
import { PUT_API_URLS } from 'constants/apiUrls';
import CircularSpinner from "common/CircularSpinner";

export const CallerTracker = () => {
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
    const [hasUserPermission, setHasUserPermission] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<any>(null); 
    const [location, setLocation] = useState<any>(null);

    useEffect(() => {
        const data = {};
        put(PUT_API_URLS.CALLER_CONNECTION(params['sessionToken'] || ''), data).then((res:AxiosResponse) => {
            if (res.status !== 200) {
                enqueueSnackbar('Error while establishing connection.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while establishing connection.', {variant: 'error'});
        });
    }, []);

    const handleGeolocationError = (error:any) => {
        let errorMessage = error.message;
        switch(error.code) {
            case error.PERMISSION_DENIED:
                if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                    errorMessage = "User denied the request for Geolocation. Please enable the location sharing by selecting Settings > Privacy & Security > Location Services > Safari Websites > Ask Next Time Or When I Share and try reloading the website."
                } else {
                    errorMessage = "User denied the request for Geolocation. Please enable the location sharing with this web browser and try reloading the website."
                }
              break;
            case error.POSITION_UNAVAILABLE:
                errorMessage = "Location information is unavailable."
              break;
            case error.TIMEOUT:
                errorMessage = "The request to get user location timed out."
              break;
            case error.UNKNOWN_ERROR:
                errorMessage  = "An unknown error occurred."
              break;
            default:
                errorMessage = error.message;
                break;
          }

          alert(errorMessage);
    }

    const shareLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onPositionUpdate, handleGeolocationError);
        } else {
            enqueueSnackbar('Location sharing is not supported by this browser. Please make sure that you have enabled location sharing for this browser.', {variant: 'error'});
        }
    }

    useEffect(() => {
        if (hasUserPermission) {
            if (!timeoutId) {
                shareLocation();
            }
        } else {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, [hasUserPermission, timeoutId]);


    const onPositionUpdate = (position:any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setLocation({lat:latitude, lng:longitude});

        const data = {
            latitude: latitude,
            longitude: longitude
        };

        
        put(PUT_API_URLS.CALLER_LOCATION_UDPATE(params['sessionToken'] || ''), data).then((res:AxiosResponse) => {
            if (res.status === 200) {
                const _timeoutId = setTimeout(shareLocation, 5000);
                setTimeoutId(_timeoutId);
                enqueueSnackbar('Shared your latest location.', {variant: 'success'});
            } else {
                enqueueSnackbar('Error while sharing your location.', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while sharing your location.', {variant: 'error'});
        })
    }

    const googleMapsApiKey:string = process.env.REACT_APP_GOOGLE_API_KEY || '';
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: googleMapsApiKey
    });

    return (
        <>
            {
                !isLoaded && <CircularSpinner/>
            }
            <Stack spacing={2} style={{width:'100%', height:'100%'}}>
                <Typography variant='h5'>
                    {hasUserPermission?'Sharing your location with I3 EMS': 'Sorry! We cannot share your location unless you give us your permission'}
                </Typography>
                {
                    isLoaded && (
                        <GoogleMap
                            mapContainerClassName='map-container'
                            center={location || {lat:39, lng:-104}}
                            zoom={location?10:2}
                        >
                            {location && 
                                <Marker position={location}/>
                            }
                        </GoogleMap>

                    )
                }
            </Stack>

            <Dialog
                open={isDialogOpen}
                onClose={() => {}}
                aria-labelledby="permission-dialog-title"
                aria-describedby="permission-dialog-description"
            >
                <DialogTitle id="permission-dialog-title">
                    Location Tracking Permission
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="permission-dialog-description">
                    Do you want to share you location with I3 EMS? This means sending your
                    location data to I3 EMS until the app is running. Please close the app if you want to stop sharing your location.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button 
                    onClick={() => {
                        setHasUserPermission(false);
                        setIsDialogOpen(false);
                    }}
                >
                    Disagree
                </Button>
                <Button 
                    onClick={() => {
                        setHasUserPermission(true);
                        setIsDialogOpen(false);
                    }}
                >
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CallerTracker;