const ROOT_URL = process.env.REACT_APP_API_URL

export const GET_API_URLS = {
    ACTIVE_CALLERS: (userId:number) => `/users/${userId}/callers/active`,
    ARCHIVE_CALLERS: (userId:number) => `/users/${userId}/callers/archive`,
    CALLER: (userId:number, callerId: number) => `/users/${userId}/callers/${callerId}`,
    CALLER_LOG: (userId:number, callerId: number) => `/users/${userId}/callers/${callerId}/log`,    
}

export const POST_API_URLS = {
    LOGIN: () => `/dj-rest-auth/login/`,
    CALLER_REGISTRATION: (userId:number) => `/users/${userId}/callers`,
}

export const PUT_API_URLS = {
    CALLER_CONNECTION: (sessionToken:string) => `/users/callers/token/${sessionToken}/connection`,
    CALLER_LOCATION_UDPATE: (sessionToken:string) => `/users/callers/token/${sessionToken}/location`,
    CALLER_SESSION_TERMINATION: (userId:number, callerId:number) => `/users/${userId}/callers/${callerId}/terminate`,
}

export const DELETE_API_URLS = {}