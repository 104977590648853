import React from 'react';
import {AppBar, Typography, IconButton, Menu, MenuItem, Toolbar} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom"

import { isLoggedIn, logout } from "utils/api";


export const NavigationBar = () => {
    const navigate = useNavigate();
    const isUserLoggedIn = isLoggedIn();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

    const toLogout = (event: React.MouseEvent) => {
        logout();
        navigate("/login");
      }
    
    return (
        <AppBar position='static'>
            <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight:'bolder' }}>
                I3 Emergency Services
            </Typography>
            {isUserLoggedIn && (
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={toLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            )}
            </Toolbar>
        </AppBar>
    );
}

export default NavigationBar;