import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import Dashboard from 'components/Dashboard';
import Login from 'components/Login';
import CallerTracker from 'components/CallerTracker';

export const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<Login/>}/>
                <Route path='/dashboard' element={<Dashboard/>}/>
                <Route path='/location-share/:sessionToken' element={<CallerTracker />}/>
                <Route path='*' element={<Navigate to='/login' replace/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default RouterConfig;