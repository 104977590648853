import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Tab, Box, Typography, Link, Container } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import ArchiveIcon from '@mui/icons-material/Archive';

import NavigationBar from "common/NavigationBar";
import ActiveCallsDashboard from './ActiveCallsDashboard';
import ArchiveCallsDashboard from './ArchiveCallsDashboard';


export const Dashboard = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
        <>
            <NavigationBar/>
            <Box 
                style={{
                    marginBottom:'25px', 
                    marginLeft:'25px', 
                    marginRight:'25px', 
                    width:'calc(100% - 26px)', 
                    height: 'calc(100% - 140px)'
                    }}
            >
                <Tabs
                    value={selectedTab}
                    onChange={(event: React.SyntheticEvent, newSelectedTab: number) => {
                        setSelectedTab(newSelectedTab);
                    }}
                    style={{marginBottom:'20px'}}
                >
                    <Tab 
                        icon={<CallIcon/>} 
                        iconPosition='end' 
                        label={<Typography variant='button' style={{fontWeight:'bolder'}}
                    >
                        Active Calls
                    </Typography>}/>
                    <Tab 
                        icon={<ArchiveIcon/>} 
                        iconPosition='end' 
                        label={<Typography variant='button' 
                        style={{fontWeight:'bolder'}}
                    >
                        Archive Calls
                    </Typography>}/>
                </Tabs>
                {(selectedTab === 0) && <ActiveCallsDashboard/>}
                {(selectedTab === 1) && <ArchiveCallsDashboard/>}
            </Box>
        </>
    );
}

export default Dashboard;