import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import { useSnackbar} from 'notistack';
import { AxiosResponse, AxiosError } from "axios";
import { TextField, Button } from "@mui/material";
import * as _ from 'lodash';

import NavigationBar from "common/NavigationBar";
import CircularSpinner from "common/CircularSpinner";
import { post } from "utils/api";
import { POST_API_URLS } from "constants/apiUrls";
import { TOKEN_KEY, USER_ID } from "constants/constants";


const Login = () => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const[isLoading, setIsLoading] = useState<boolean>(false);

    const login = () => {
        const data = {
          "email": email,
          "password": password
        }
    
        setIsLoading(true);
        post(POST_API_URLS.LOGIN(), data, () => {}).then( (res: AxiosResponse) => {
          if(res.status === 200){
            sessionStorage.setItem(TOKEN_KEY, res.data['key']);
            sessionStorage.setItem(USER_ID, res.data['id']);
            navigate("/dashboard");
          } else {
            enqueueSnackbar('Could not find user with that email and password', {variant: 'error'});
          }
        }).catch( (res: AxiosError) => {
          if (_.get(res, 'response.status', null) === 401) {
            enqueueSnackbar('Incorrect email or password', {variant: 'error'});
          } else {
            enqueueSnackbar('Error while logging in', {variant: 'error'});
          }
        }).finally(() => {
          setIsLoading(false);
        });
      };
    
      const sx = {m:1, width: '35ch'};

      return (
        <>
            {
                isLoading && <CircularSpinner />
            }
            <NavigationBar/>
            <div 
                style={{width: '450px', height: '332px', margin: 'auto', marginTop:'150px', 
                border: '1px solid #707070', boxShadow:'0px 3px 6px #00000029', borderRadius: '20px'}}            
            >
                <form>
                <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                    <fieldset style={{width: '330px', height: '100%', border: '1px solid #6D77B0', borderRadius: '12px',
                    padding: '12px 6px 6px 6px', marginLeft:'auto', marginRight:'auto'}}
                    >
                    <legend style={{marginLeft: '17px'}}>Sign In</legend>
                    <TextField
                        sx={sx}
                        label='Email'
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        size='small'
                    />
                    <TextField
                        sx={sx}
                        label='Password'
                        type='password'
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                        }}
                        size='small'
                    />
                    </fieldset>

                    <Button 
                        variant='contained' 
                        type='submit'
                        onClick={(e) => {
                            e.preventDefault();
                            login();
                        }}
                        style={{width:'344px', marginLeft:'auto', marginRight: 'auto', marginTop:'44px'}}
                    >
                        SIGN IN
                    </Button>
                </Stack>
                </form>
            </div>
        </>
      );
}

export default Login;