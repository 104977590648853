import React from 'react';
import logo from './logo.svg';
import 'static/css/App.css';

import RouterConfig from 'routers';

function App() {
  return (
    <div className="App" style={{width:'100%', height:'100vh'}}>
      <RouterConfig/>
    </div>
  );
}

export default App;
